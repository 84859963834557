/*Attori sia normale sia amp*/
@import "utils";

//Pagine con lista attori
.stars__header {
    background-color: #000;
    color: #fff;
    padding: 60px 0;
    text-align: center;

    @media (min-width: $s-tablet) {
        padding: 90px 0;
    }

    > div {
        position: relative;
    }
}

.stars__header-title {
    @include latoblack;
    font-size: 45px;
    position: relative;
    z-index: 5;

    @media (min-width: $l-tablet) {
        font-size: 50px;
    }

    @media (min-width: $medium) {
        font-size: 60px;
    }
}

.stars__tabs {
    border-bottom: 1px solid $border;
}

.stars__tab-list {
    align-items: center;
    display: flex;
}

.stars__tab-item {
    &:not(:last-child) {
        margin-right: 40px;

        @media (min-width: $s-tablet) {
            margin-right: 55px;
        }
    }

    &.is-active {
        > a {
            border-bottom: 2px solid $primary;
            color: $primary;

            @media (min-width: $s-tablet) {
                border-width: 3px;
            }
        }
    }

    > a {
        color: #000;
        display: block;
        @include latobold;
        font-size: 18px;
        padding: 20px 0;
        @include transition(color);

        @media (min-width: $s-tablet) {
            font-size: 20px;
            padding: 25px 0;
        }

        .no-touchevents &:hover {
            color: $primary;
        }
    }
}

.stars__section {
    margin-top: 25px;

    @media (min-width: $s-tablet) {
        margin-top: 40px;
    }
}

.stars__section-header {
    margin-bottom: 25px;

    @media (min-width: $s-tablet) {
        margin-bottom: 40px;
    }
}

.stars__section-title {
    color: #000;
    @include latobold;
    font-size: 25px;
    line-height: 1.1;

    @media (min-width: $s-tablet) {
        font-size: 35px;
    }

    @media (min-width: $medium) {
        font-size: 45px;
    }
}

.stars__section-body {
    display: flex;

    > div {
        &:first-child {
            @media (min-width: $l-tablet) {
                width: calc(100% - 330px);
            }
        }
    }
}

.stars__list {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;

    @media (min-width: $l-tablet) {
        margin: -20px;
    }

    & + amp-list {
        &[load-more] {
            padding-top: 15px;

            div[role=list] {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                &:empty {
                    display: none;
                }
            }
        }
    }
}

amp-list-load-more {
    width: 100%;
}

.stars__loading {
    @include loading;
    margin: 0;
}

.stars__aside {
    @media (max-width: $l-tablet - 1) {
        display: none;
    }

    @media (min-width: $l-tablet) {
        flex-shrink: 0;
        margin-left: 30px;
        width: 300px;
    }

    .star__main & {
        @media (min-width: $l-tablet) {
            padding-top: 40px;
        }
    }
}

.stars__section-footer {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    @media (min-width: $s-tablet) {
        margin-top: 60px;
    }

    @media (min-width: $l-tablet) {
        margin-top: 75px;
    }
}

.wish-layer {
    align-items: center;
    backface-visibility: visible;
    background-color: rgba(0,0,0,.95);
    display: flex;
    @include latoregular;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: fixed;
    top: 0;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: visible;
    width: 100%;
    z-index: 100;

    &[hidden] {
        backface-visibility: hidden;
        opacity: 0;
        visibility: hidden;
    }
}

.wish-layer__inner {
    background-color: #fff;
    border-radius: 5px;
    max-width: 750px;
    padding: 35px 35px 60px;
    position: relative;
    width: 80%;

    @media (min-width: $l-tablet) {
        padding: 45px 45px 110px;
    }
}

.wish-layer__close {
    color: #fff;
    font-size: 18px;
    position: absolute;
    right: 5px;
    top: -10px;
    transform: translateY(-100%);
    @include transition(opacity);
    z-index: 10;

    @media (min-width: $s-tablet) {
        font-size: 20px;
        right: 10px;
        top: -15px;
    }

    .no-touchevents &:hover {
        opacity: .7;
    }
}

.wish-layer__header {
    @media (max-width: $s-tablet - 1) {
        text-align: center;
    }

    @media (min-width: $s-tablet) {
        align-items: flex-end;
        display: flex;
        justify-content: center;
    }

    img {
        flex-shrink: 0;

        @media (max-width: $s-tablet - 1) {
            height: 75px;
        }

        @media (min-width: $s-tablet) {
            margin-right: 15px;
        }
    }

    span {
        @media (max-width: $s-tablet - 1) {
            display: block;
            font-size: 20px;
            margin-top: 10px;
        }

        @media (min-width: $s-tablet) {
            font-size: 25px;
            margin-bottom: 15px;
        }

        mark {
            background: none;
            color: $primary;
        }
    }
}

.wish-layer__socials {
    text-align: center;

    p {
        font-size: 15px;
        line-height: 1.1;
        margin: 20px 0 30px;

        @media (min-width: $s-tablet) {
            font-size: 18px;
            margin: 30px 0 45px;
        }
    }
}

.wish-layer__social {
    margin: 0 auto;
    max-width: 275px;
    width: 100%;

    &.wish-layer__social--twitter {
        > button {
            background-color: $twitter;

            .no-touchevents &:hover {
                background-color: darken($twitter, 10%);
            }
        }
    }

    &.wish-layer__social--facebook {
        > button {
            background-color: $facebook;

            .no-touchevents &:hover {
                background-color: darken($facebook, 10%);
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 15px;

        @media (min-width: $s-tablet) {
            margin-bottom: 25px;
        }
    }

    > button {
        align-items: center;
        border-radius: 5px;
        color: #fff;
        display: flex;
        height: 50px;
        @include transition(background-color);
        width: 100%;

        @media (min-width: $s-tablet) {
            height: 60px;
        }

        &:before {
            align-items: center;
            color: #fff;
            display: flex;
            flex-shrink: 0;
            font-size: 24px;
            height: 100%;
            justify-content: flex-end;
            width: 35px;

            @media (min-width: $s-tablet) {
                font-size: 28px;
                width: 45px;
            }
        }

        span {
            display: block;
            font-size: 15px;
            letter-spacing: 0.19px;
            text-align: center;
            width: 100%;

            @media (min-width: $s-tablet) {
                font-size: 19px;
                letter-spacing: 0.39px;
            }
        }
    }
}

//Singolo attore
.star__hero {
    align-items: center;
    background-color: #000;
    display: flex;
    height: 0;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 83.33%;
    position: relative;
    text-align: center;

    @media (min-width: $l-tablet) {
        display: none;
    }
}

.star__hero-image {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    z-index: 5;

    &:before {
        background-color: rgba(0,0,0,.5);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
    }

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
    }
}

.star__header {
    background-color: #000;
    padding: 20px 0;
    position: relative;
    z-index: 15;

    @media (min-width: $s-tablet) {
        padding: 35px 0;
    }

    > div {
        display: flex;
    }
}

.star__header-photo {
    @media (max-width: $l-tablet - 1) {
        display: none;
    }

    @media (min-width: $l-tablet) {
        border-radius: 5px;
        flex-shrink: 0;
        height: 215px;
        margin-right: 45px;
        overflow: hidden;
        position: relative;
        width: 215px;
    }

    &.loading {
        background-color: $grey;

        &:after {
            left: 50%;
            margin-left: -15px;
            margin-top: -15px;
            position: absolute;
            top: 50%;
            z-index: 10;

            @media (min-width: $s-tablet) {
                margin-left: -20px;
                margin-top: -20px;
            }
        }
    }

    img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: center center;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.star__header-info {
    display: flex;
    flex-wrap: wrap;
    @include latoregular;
    font-size: 14px;
    letter-spacing: .14px;
    margin: -10px -15px;

    @media (min-width: $s-tablet) {
        font-size: 18px;
        letter-spacing: .18px;
        margin: -30px;
    }

    > div {
        padding: 10px 15px;

        @media (min-width: $s-tablet) {
            padding: 30px;
        }

        &:first-child {
            width: 100%;

            @media (max-width: $l-tablet - 1) {
                left: 0;
                position: absolute;
                text-align: center;
                top: -41.665vw;
                transform: translateY(-50%);
                z-index: 10;
            }
        }

        &:not(:first-child) {
            p {
                color: #ccc;
                margin: 5px 0 0;
            }
        }
    }

    span {
        color: #fff;
    }
}

.star__header-name {
    color: #fff;
    @include latoblack;
    font-size: 45px;
    line-height: 1.05;
    position: relative;
    z-index: 5;

    @media (min-width: $l-tablet) {
        font-size: 50px;
    }

    @media (min-width: $medium) {
        font-size: 60px;
    }

    > a {
        @include transition(color);

        .no-touchevents &:hover {
            color: $primary;
        }
    }
}

.star__header-desc {
    color: #fff;
    @include latobold;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.16px;
    margin: 15px 0 0;

    @media (min-width: $l-tablet) {
        font-size: 18px;
        letter-spacing: 0.18px;
    }
}

.star__sliderbtn {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 24px;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include transition;
    width: 55px;
    z-index: 10;

    @media (max-width: ($s-tablet - 1)) {
        display: none;
    }

    &:after {
        @media (min-width: $s-tablet) {
            background-color: #fff;
            content: "";
            height: 102%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            z-index: 5;
        }
    }

    &.swiper-button-disabled {
        display: none;
    }

    .no-touchevents &:hover {
        background-color: #000;
    }

    .star__movies & {
        @media (min-width: $s-tablet) {
            height: calc(100% - (50px * 2)); //padding swiper-container
        }
    }

    .star__relateds & {
        height: calc(100% - (10px * 2)); //padding swiper-slide
    }
}

.star__sliderbtn--prev {
    left: 0;

    &:after {
        @media (min-width: $s-tablet) {
            right: 100%;
        }
    }

    .star__movies & {
        @media (min-width: $s-tablet) {
            left: 62.5px;
        }
    }

    .star__relateds & {
        left: 7.5px;

        @media (min-width: $s-tablet) {
            left: 12.5px;
        }
    }
}

.star__sliderbtn--next {
    right: 0;

    &:after {
        @media (min-width: $s-tablet) {
            left: 100%;
        }
    }

    .star__movies & {
        @media (min-width: $s-tablet) {
            right: 62.5px;
        }
    }

    .star__relateds & {
        right: 7.5px;

        @media (min-width: $s-tablet) {
            right: 12.5px;
        }
    }
}

.star__section {
    margin-top: 30px;

    @media (min-width: $s-tablet) {
        margin-top: 40px;
    }

    .star__main > div & {
        &:first-child {
            width: 100%;
        }
    }
}

.star__section-title {
    color: #000;
    @include latoblack;
    font-size: 20px;
    position: relative;

    @media (min-width: $s-tablet) {
        font-size: 28px;
    }
}

.star__section-text {
    font-size: 18px;
    letter-spacing: .21px;
    line-height: 1.67;
    margin-top: 15px;

    p {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        a:not([class*="icon-"]) {
            background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
            background-repeat: repeat-x;
            background-position: 0 22px;
            background-size: 1px 1px;
            color: $primary;
            text-decoration: none;
            position: relative;
            text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
            z-index: 2;
        }
    }
}

.star__section-footer {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .star__filmography + & {
        @media (max-width: $s-tablet - 1) {
            margin-top: 20px;
        }
    }
}

.star__movies {
    margin-top: 15px;

    @media (min-width: $s-tablet) {
        margin: -20px -50px -50px;
    }

    .swiper-container {
        @media (max-width: $s-tablet - 1) {
            margin: 0 32.5px 0 -7.5px;
            overflow: visible;
        }

        @media (min-width: $s-tablet) {
            margin: -12.5px;
            padding: 50px;
        }

        &:before,
        &:after {
            @media (min-width: $s-tablet) {
                background-color: #fff;
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                width: 25px;
                z-index: 5;
            }
        }

        &:before {
            @media (min-width: $s-tablet) {
                left: 0;
            }
        }

        &:after {
            @media (min-width: $s-tablet) {
                right: 0;
            }
        }
    }

    amp-carousel {
        .movies__item {
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
}

.star__main {
    > div {
        @media (min-width: $l-tablet) {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.star__sections {
    @media (min-width: $l-tablet) {
        width: calc(100% - 330px);
    }
}

.star__relateds {
    margin-top: 15px;

    @media (min-width: $s-tablet) {
        margin-top: 30px;
    }
}

.star__relateds-list {
    @media (max-width: ($s-tablet - 1)) {
        margin: -10px 32.5px -10px -7.5px; //40 - margin
        overflow: visible;
    }

    @media (min-width: $s-tablet) {
        margin: -10px -12.5px;
    }
}

.star__news {
    margin-top: -15px;

    @media (min-width: $s-tablet) {
        margin-top: 30px;
    }
}

.star__filmography {
    border: 1px solid $border;
    margin-top: 15px;

    @media (min-width: $s-tablet) {
        margin-top: 30px;
    }
}

.star__filmography-section {
    @include latoregular;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 1.15;
    padding: 15px;

    @media (min-width: $s-tablet) {
        display: flex;
        font-size: 20px;
        letter-spacing: 0.2px;
        padding: 20px 25px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $border;
    }

    span {
        color: #000;
        min-width: 55px;

        &:empty {
            & + .star__filmography-list {
                @media (max-width: $s-tablet - 1) {
                    margin-top: 0;
                }
            }
        }
    }
}

.star__filmography-list {
    width: 100%;

    @media (max-width: $s-tablet - 1) {
        margin-top: 15px;
    }

    @media (min-width: $s-tablet) {
        margin-left: 15px;
    }


}

.star__filmography-item {
    &:not(:last-child) {
        margin-bottom: 10px;

        @media (min-width: $s-tablet) {
            margin-bottom: 15px;
        }
    }

    &.star__filmography-item--series,
    &.star__filmography-item--movie {
        padding-left: 70px;
        position: relative;

        @media (min-width: $s-tablet) {
            padding-left: 95px;
        }

        &:before {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 3px;
            color: #666;
            display: block;
            flex-shrink: 0;
            font-size: 10px;
            font-weight: 900;
            left: 0;
            letter-spacing: 0.1px;
            line-height: 1.6;
            padding: 1px 7px;
            pointer-events: none;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 0;
            @include transition;
            white-space: nowrap;
            width: 57px;

            @media (min-width: $s-tablet) {
                font-size: 12px;
                width: 72px;
            }
        }
    }

    &.star__filmography-item--series {
        &:before {
            content: "SERIE TV";
        }
    }

    &.star__filmography-item--movie {
        &:before {
            content: "FILM";
        }
    }

    > a {
        .no-touchevents &:hover {
            strong {
                color: $primary;
            }
        }

        strong {
            @include transition(color);
        }
    }

    > p {
        color: #666;
        display: inline;
        margin: 0;
    }
}